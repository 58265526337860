import NavigationBar from "./NavigationBar";
import { Outlet } from "react-router-dom";
import React from "react";
function RootLayout() {
  return (
    <React.Fragment>
      <NavigationBar />
      <main>
        <Outlet />
      </main>
    </React.Fragment>
  );
}

export default RootLayout;
