import styled from "styled-components";
import "./Skills.css";
import { SKILLS } from "../hooks/constans";
const SkillsBubble = () => {
  return (
    <Styles.Parent>
      {SKILLS.map((value) => {
        return (
          <div key={value.title} className="skill">
            <svg>{value.logo}</svg>
            <h3>{value.title}</h3>
          </div>
        );
      })}
    </Styles.Parent>
  );
};

export default SkillsBubble;

const Styles = {
  Parent: styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 12px;

    margin: 2rem 0;

    @media only screen and (max-width: 500px) {
      grid-gap: 12px;
      grid-template-columns: repeat(5, 1fr);
      padding: 10px;
      margin: 0;
    }
  `,
};
