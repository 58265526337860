import styled from "styled-components";
import SkillBar from "react-skillbars";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { BsDiscord } from "react-icons/bs";
import { skills, colors } from "../hooks/constans";
import me from "../Addons/mee.jpg";
import SkillsBubble from "./Skills";
import { ProjectCarousel } from "./Language carousel/ProjectCarousel";
import { useLoaderData } from "react-router-dom";

// import { TableBar } from "../hooks/tableBar";
const Home = () => {
  const data = useLoaderData();
  return (
    <Styles.HomeParent>
      <Styles.ProfileContainer>
        <Styles.Profile alt="photo of Patrick" src={me} />
        <Styles.ProfileTag>Frontend Developer</Styles.ProfileTag>
        <Styles.ProfileLink
          href="https://www.linkedin.com/in/patrick-karkusz-666a87234/"
          target="_blank"
          rel="noreferrer"
        >
          @patrick_karkusz
        </Styles.ProfileLink>
        <Styles.SocialIcons>
          <a
            href="https://github.com/Patrixxk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/patrick-karkusz-666a87234/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillLinkedin />
          </a>
          <a
            href="https://discord.gg/x5C3W64j"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsDiscord />
          </a>
        </Styles.SocialIcons>
      </Styles.ProfileContainer>
      <div className="text-Parent">
        <Styles.ProfileTextHead>
          I'm a junior Frontend Developer with a year of freelance experience.
        </Styles.ProfileTextHead>
        <Styles.ProfileTextParent>
          <Styles.ProfileText style={{ margin: "0", padding: "8px" }}>
            Proficient in JavaScript, React, CSS, HTML, and a bit Typescript. My
            expertise lies in creating intuitive web solutions through a
            combination of technical knowledge and a keen eye for user-centric
            design. Comfortable with leveraging APIs and solving problems to
            meet business objectives, I'm passionate about continuous learning
            and growth.
          </Styles.ProfileText>
        </Styles.ProfileTextParent>
      </div>
      {/* <Styles.SkillBarParent>
        <SkillBar skills={skills} colors={colors} />
      </Styles.SkillBarParent> */}
      <SkillsBubble />
      <div
        style={{
          padding: "10px 10px",
          maxWidth: "100%",
          //left: "50%",
          //marginLeft: "-50vw",
          height: "250px",
          //position: "relative",

          // aspectRatio: "16 / 9",
        }}
      >
        <ProjectCarousel work={data} />
      </div>
    </Styles.HomeParent>
  );
};

export default Home;

const Styles = {
  HomeParent: styled.section`
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;

    @media only screen and (max-width: 500px) {
      font-size: 12px;
    }
  `,
  ProfileContainer: styled.div`
    width: 100%;
    @media only screen and (min-width: 768px) {
      display: grid;
      align-items: center;
      justify-content: space-between;
      justify-items: center;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      gap: 10px;

      padding: 1em;
    }
    @media only screen and (max-width: 500px) {
      margin: 0.5rem auto;
      max-width: 95%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media only screen and (min-width: 501px) and (max-width: 767px) {
      max-width: 95%;
      display: flex;
      flex-direction: column;

      align-items: center;
    }
  `,
  Profile: styled.img`
    // margin-bottom: 0.5rem;
    border-radius: 50%;
    height: 120px;
    width: 100px;

    grid-column: 1;
    grid-row: 1;
  `,
  ProfileLink: styled.a`
    grid-column: 2;
    font-family: var(--primary-font);
    grid-row: 1 / span 2;
    height: min-content;

    color: var(--text-color);
    font-weight: bold;
    font-size: 1.8em;
    text-decoration: none;

    text-shadow: 2px 2px 2px rgba(192, 149, 118, 0.5);
    &:hover {
      font-weight: bold;
      font-size: 1.8em;
      color: var(--text-color);
      text-shadow: 1px 1px 3px var(--fourth-color);
    }
  `,
  ProfileTag: styled.h3`
    grid-column: 1;
    grid-row: 2;
    font-size: 1.6em;
    font-family: var(--primary-font);
    letter-spacing: 1px;
    @keyframes move {
      0% {
        transform: translateX(0);
        color: var(--fourth-color);
      }
      50% {
        transform: translateX(20px);
        color: var(--primary-color);
      }
      100% {
        transform: translateX(0);
        color: var(--fourth-color);
      }
    }

    animation: move 2s linear infinite;
  `,
  ProfileTextParent: styled.div`
    padding: 8px;
    background-color: rgba(30, 30, 30, 0.8);
    align-items: center;
    border: 2px solid var(--fourth-color);
    border-radius: 3.5em;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7), 0 20px 40px rgba(0, 0, 0, 0.9);
  `,
  ProfileTextHead: styled.p`
    padding: 8px;
    margin: 0;
    margin-bottom: 12px;
    color: var(--fourth-color);
    font-weight: bold;
    font-family: "Roboto Mono", monospace;
    font-size: 1.2rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    text-aling: center;
    text-shadow: 1px 1px 3px black;
    @media only screen and (max-width: 500px) {
      font-size: 0.95rem;
    }
    @media only screen and (min-width: 501px) and (max-width: 768px) {
      font-size: 0.9rem;
    }
    @media only screen and (min-width: 769px) and (max-width: 992px) {
      font-size: 0.9rem;

      padding: 10px;
    }
    @media only screen and (min-width: 993px) and (max-width: 1200px) {
      padding: 10px;
    }
  `,
  ProfileText: styled.p`
    font-family: "Roboto Mono", monospace;
    font-size: 1rem;
    color: var(--text-color);
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    text-aling: center;
    @media only screen and (max-width: 500px) {
      font-size: 0.85rem;
    }
    @media only screen and (min-width: 501px) and (max-width: 768px) {
      font-size: 0.85rem;
    }
    @media only screen and (min-width: 769px) and (max-width: 992px) {
      font-size: 0.9rem;

      padding: 10px;
    }
    @media only screen and (min-width: 993px) and (max-width: 1200px) {
      padding: 10px;
    }
  `,

  Button: styled.button`
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    background-color: #008080;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #16a085;
    }

    &:focus {
      outline: none;
    }
  `,
  SocialIcons: styled.div`
    grid-column: 2;
    grid-row: 2 / 4;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 2rem;

    svg {
      fill: var(--text-color);
      cursor: pointer;
      transition: 0.5s ease-out;
      @media (hover: hover) {
        &:hover {
          fill: var(--fourth-color);
          transform: scale(1.1);
          transition: font-size fill 0.5s ease-in-out;
        }
      }
    }
    @media only screen and (max-width: 500px) {
      font-size: 2rem;
      margin-top: 1.2rem;
    }
    @media only screen and (min-width: 501px) and (max-width: 767px) {
      font-size: 2.5rem;
      margin-top: 1.2rem;
    }
  `,
};
