import { useState, useRef, React, Fragment, useEffect } from "react";
import { ArrowBigLeft, ArrowBigRight, Circle, CircleDot } from "lucide-react";
import "./projectcarousel.css";
import styled from "styled-components";
import { icons } from "../../hooks/constans";

export const ProjectCarousel = ({ work }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [showWork, setShowWork] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const targetCarouselRef = useRef(null);

  const loadedWork = Object.keys(work).map((key) => ({
    id: key,
    title: work[key].title,
    description: work[key].description,
    language: work[key].language,
    image: work[key].image,
    link: work[key].link,
  }));
  //test
  const images = loadedWork.map((item, index) => {
    return item.image.images;
  });
  //
  function showNextImage() {
    setImageIndex((index) => {
      if (index === loadedWork.length - 1) return 0;
      return index + 1;
    });
  }

  function showPrevImage() {
    setImageIndex((index) => {
      if (index === 0) return loadedWork.length - 1;
      return index - 1;
    });
  }

  function showWorkDetails(index) {
    console.log(index);
    setImageIndex(index);
    setShowWork(true);

    setTimeout(() => {
      targetCarouselRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  }

  function hideWorkDetails() {
    setShowWork(false);
  }
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getTranslateXValue = () => {
    if (windowWidth <= 500) {
      return -103.5 * imageIndex;
    } else if (windowWidth >= 1180) {
      return -80.5 * imageIndex;
    } else if (windowWidth >= 997 && windowWidth <= 1179) {
      return -84.5 * imageIndex;
    } else if (windowWidth >= 501 && windowWidth <= 767) {
      return -91.5 * imageIndex;
    } else {
      return -69.5 * imageIndex;
    }
  };

  return (
    <section
      aria-label="Image slider"
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        margin: "2rem 0",
      }}
    >
      <a className="skip-link" href="#after-image-slider-contorls">
        Skip Image slider
      </a>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          display: "flex",
          gap: "15px",
          boxShadow:
            "0 10px 30px rgba(0, 0, 0, 0.7), 0 20px 40px rgba(0, 0, 0, 0.9)",
          borderRadius: "15px",
          cursor: "pointer",
        }}
      >
        {loadedWork.map((item, index) => (
          <>
            <img
              key={item.id}
              src={item?.image?.thumbnail}
              alt={item.title}
              aria-hidden={imageIndex !== index}
              className="img-slider-img"
              id="img-slider"
              style={{
                transform: `translateX(${getTranslateXValue()}%)`,
                transition: "transform 0.5s ease-in-out",
              }}
              onClick={() => showWorkDetails(index)}
            />
          </>
        ))}
      </div>
      {showWork ? (
        <SectionComp
          style={{
            height: "475px",
            marginLeft: "calc(-35vw + 50%)",
            marginTop: "4rem",
            width: "70vw",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <WorkDetails ref={targetCarouselRef}>
            {/* <WorkDetailsImages> */}
            <div class="container">
              <input type="radio" name="slide" id="c1" checked />
              <label
                for="c1"
                className="card"
                style={{
                  backgroundImage: `url(${loadedWork[imageIndex].image?.images[0]})`,
                }}
              >
                <div class="row">
                  <div class="icon">1</div>
                  {/* <Img1
                      src={loadedWork[imageIndex].image?.images[2]}
                      alt={loadedWork[imageIndex].title}
                    /> */}
                </div>
              </label>

              <input type="radio" name="slide" id="c2" />
              <label
                for="c2"
                className="card"
                style={{
                  backgroundImage: `url(${loadedWork[imageIndex].image?.images[1]})`,
                }}
              >
                <div class="row">
                  <div class="icon">2</div>
                  {/* <Img2
                      src={loadedWork[imageIndex].image?.images[2]}
                      alt={loadedWork[imageIndex].title}
                    /> */}
                </div>
              </label>

              <input type="radio" name="slide" id="c3" />
              <label
                for="c3"
                className="card"
                style={{
                  backgroundImage: `url(${loadedWork[imageIndex].image?.images[2]})`,
                }}
              >
                <div class="row">
                  <div class="icon">3</div>
                  {/* <Img3
                      src={loadedWork[imageIndex].image?.images[2]}
                      alt={loadedWork[imageIndex].title}
                    /> */}
                </div>
              </label>
            </div>
            {/* </WorkDetailsImages> */}
            <WorkDetailHolder>
              <HideDetails onClick={hideWorkDetails}>Hide Details</HideDetails>
              <WorkDetailsText>
                <h2 className="work-title">{loadedWork[imageIndex].title}</h2>
                <SocialIconsParent>
                  {loadedWork[imageIndex].language
                    .split(",")
                    .map((language, index) => {
                      const IconElement = icons[language.trim()];

                      return IconElement ? (
                        <Fragment key={index}>{IconElement}</Fragment>
                      ) : null;
                    })}
                </SocialIconsParent>
                <p className="description-text">
                  {loadedWork[imageIndex].description}
                </p>
                <a
                  href={loadedWork[imageIndex].link}
                  rel="noreferrer"
                  target="_blank"
                >
                  <StyledButton>Let's see it</StyledButton>
                </a>
              </WorkDetailsText>
            </WorkDetailHolder>
          </WorkDetails>
        </SectionComp>
      ) : null}
      <button
        onClick={showPrevImage}
        className="img-slider-btn"
        aria-label="View previous image"
        style={{ left: 0 }}
      >
        <ArrowBigLeft aria-hidden />
      </button>
      <button
        onClick={showNextImage}
        className="img-slider-btn"
        aria-label="View Next image"
        style={{ right: 0 }}
      >
        <ArrowBigRight aria-hidden />
      </button>
      <div
        style={{
          position: "absolute",
          bottom: ".5rem",
          left: "50%",
          translate: "-50%",
          display: "flex",
          gap: ".25rem",
        }}
      >
        {images.map((_, index) => (
          <button
            key={index}
            className="img-slider-dot-btn"
            aria-label={`View image ${index + 1}`}
            onClick={() => setImageIndex(index)}
          >
            {index === imageIndex ? (
              <CircleDot aria-hidden />
            ) : (
              <Circle aria-hidden />
            )}
          </button>
        ))}
      </div>
      <div id="after-image-slider-contorls"></div>
    </section>
  );
};

const SectionComp = styled.section`
  height: 475px;
  margin-left: calc(-35vw + 50%);
  margin-top: 4rem;
  width: 70vw;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 500px) {
    width: 100% !important;
    margin: 0 !important;
    margin-top: 2rem !important;
  }
  @media only screen and (min-width: 501px) and (max-width: 767px) {
    width: 100% !important;
    margin: 0 !important;
    margin-top: 2rem !important;
  }
  @media only screen and (min-width: 768px) and (max-width: 997px) {
    width: 100% !important;
    margin: 0 !important;
    margin-top: 2rem !important;
  }
`;
const WorkDetails = styled.div`
  display: grid;
  // background-color: rgba(30, 30, 30, 0.8);
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  // border-radius: 12px;
  // border: 2px solid var(--fourth-color);
  align-items: center;
  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 10px;
  }
`;
const WorkDetailHolder = styled.div`
  display: flex;
  flex-direction: column;
`;
const WorkDetailsImages = styled.div`
  display: grid;
  // margin: 1rem;
  gap: 0.1rem;
`;

const Img1 = styled.img`
  // grid-column-start: 1;
  // grid-column-end: 1;
  max-width: 460px;
  // z-index: 3;
  width: 100%;
  //object-fit: cover;
  // aspect-ratio: 16/9;
  // object-position: left;
  //margin-right: -10px;
  // transition: transform 100ms ease-in-out;
  // &:hover {
  //transform: scale(1.5);
  //object-position: left;
  //  transition: transform 100ms ease-in-out;
  //}
`;
const Img2 = styled.img`
  // grid-column-start: 2;
  // grid-column-end: 2;
  width: 460px;
  height: 40px;

  object-fit: cover;
  // scale: 1.1;
  //margin-left: -10px;
  // object-fit: cover;
  //aspect-ratio: 16/9;
  // transition: transform 100ms ease-in-out, object-position 100ms ease-in-out;
  &:hover {
    // transform: scale(1.5);
    // object-position: right;
    //z-index: 99;
    //transition: transform 100ms ease-in-out, object-position 100ms ease-in-out;
  }
`;
const Img3 = styled.img`
  // grid-column: span 4;
  // scale: 0.9;
  width: 460px;
  height: 40px;

  object-fit: cover;
  // z-index: 2;
  aspect-ratio: 16/9;
  //transition: transform 100ms ease-in-out, object-position 100ms ease-in-out;
  // &:hover {
  // transform: scale(1.3);
  // object-position: left;
  //z-index: 99;

  // transition: transform 100ms ease-in-out, object-position 100ms ease-in-out;
  //}
`;

const WorkDetailsText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(30, 30, 30, 0.8);
  border: 2px solid var(--fourth-color);
  border-radius: 12px;
`;
const SocialIconsParent = styled.div`
  width: 100%;
  padding: 5px 0;
  margin: 20px 0;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  font-size: 2.2rem;
`;

const StyledButton = styled.button`
  background-color: transparent;
  color: #ffffff;
  font-size: 18px;
  font-family: "Kdam Thmor Pro", sans-serif;
  border: solid 2px var(--fourth-color);
  border-radius: 12px;
  padding: 1rem 3.5rem;
  width: 100%;
  margin: 2rem auto;
  cursor: pointer;
  text-transform: capitalize;
  transition: color 0.3s, border-color 0.3s;

  &:hover {
    color: var(--fourth-color);
    border-color: #ffffff;
  }
`;

const HideDetails = styled.p`
  line-height: 2px;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  padding: 1px 2px;
  margin: 10px 2px;
  background-color: inherit;
  align-self: end;
  &:hover {
    color: var(--fourth-color);
  }
`;
