import styled from "styled-components";
import React from "react";
import "./work.css";
import { icons } from "./constans";
export const WorkBar = ({ works }) => {
  const languages = [];
  languages.push(works.language);
  let WorkComponent;
  switch (works.id) {
    case "w1":
      WorkComponent = Styles.WorkChild1;
      break;
    case "w2":
      WorkComponent = Styles.WorkChild2;
      break;
    case "w3":
      WorkComponent = Styles.WorkChild3;
      break;
    case "w4":
      WorkComponent = Styles.WorkChild4;
      break;
    case "w5":
      WorkComponent = Styles.WorkChild5;
      break;
    case "w6":
      WorkComponent = Styles.WorkChild6;
      break;
    case "w7":
      WorkComponent = Styles.WorkChild7;
      break;
    case "w8":
      WorkComponent = Styles.WorkChild8;
      break;
    default:
      WorkComponent = WorkChild;
  }
  return (
    <WorkComponent key={works.id}>
      <Styles.SSS>
        <a
          className="image-link"
          href={works.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="img-work"src={works.image.images[0]} alt={works.title} loading="lazy" />
        </a>
        <h3>{works.title}</h3>
        <p>{works.description}</p>
        <Styles.SocialIconsParent>
          {languages.map((languages) => {
            return languages.split(",").map((language) => {
              const IconElement = icons[language];

              return IconElement ? (
                <React.Fragment key={language}>{IconElement}</React.Fragment>
              ) : null;
            });
          })}
        </Styles.SocialIconsParent>
      </Styles.SSS>
    </WorkComponent>
  );
};
const WorkChild = styled.div`
  border-radius: 10px;
  padding: 12px;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 5px 10px rgba(25, 25, 25, 0.5);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.7);
  }
  @media only screen and (max-width: 420px) {
    img {
      height: 200px;
    }
  }
`;
const Styles = {
  SSS: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  WorkChild1: styled(WorkChild)`
    grid-area: area1;
    background: linear-gradient(to right, #b28367, #694336, #9e877f);
  `,

  WorkChild2: styled(WorkChild)`
    grid-area: area2;
    background: linear-gradient(to right, #cdb300, #688a3e, #345d11, #a99200);
  `,

  WorkChild3: styled(WorkChild)`
    grid-area: area3;
  background: linear-gradient(to right, #110209, #010665, #0E0317, #1B131A);
  }
  `,

  WorkChild4: styled(WorkChild)`
    grid-area: area4;
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.75),
      rgba(244, 121, 134, 0.8),
      rgba(52, 155, 235, 0.9)
    );
  `,
  WorkChild5: styled(WorkChild)`
    grid-area: area1;
    background: linear-gradient(15deg, #c19d63, #a0bdcb, #7e7d85);
  `,

  WorkChild6: styled(WorkChild)`
    grid-area: area2;
    background: linear-gradient(
      75deg,
      #393536 0 0,
      9%,
      rgba(246, 135, 18, 0.8),
      rgba(255, 140, 0, 0.7),
      #393536
    );
  `,

  WorkChild7: styled(WorkChild)`
    grid-area: area3;
    background: linear-gradient(to right, #c19d63, #a0bdcb, #7e7d85);
  `,

  WorkChild8: styled(WorkChild)`
    grid-area: area4;
    background: linear-gradient(45deg, #0e0e0e, #a87b61, #cd5c5c, #d3d3d3);
  `,
  SocialIconsParent: styled.div`
    width: 100%;
    padding: 5px 0;
    margin: 3px 0;

    display: flex;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.6rem;
  `,
};
