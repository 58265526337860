import styled from "styled-components";
import Inputs from "../hooks/contact-inputs";

import React, { useState, useEffect } from "react";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { BsDiscord } from "react-icons/bs";
import "./contact.css";
const source = (value) => value.trim() !== "";

function Contact() {
  const [loading, setLoading] = useState(true);
  //name Validation
  const {
    value: name,
    valueIsValid: nameValid,
    hasError: nameError,
    valueChangeHandler: nameChangeHandler,
    blurHandler: nameBlur,
    reset: nameReset,
  } = Inputs(source);

  //email Validation
  const {
    value: email,
    valueIsValid: emailValid,
    hasError: emailError,
    blurHandler: emailBlur,
    valueChangeHandler: emailChangeHandler,
    reset: emailReset,
  } = Inputs(source);

  //text Validation
  const {
    value: text,
    valueIsValid: textValid,
    hasError: textError,
    blurHandler: textBlur,
    valueChangeHandler: textChangeHandler,
    reset: textReset,
  } = Inputs(source);
  let formIsValid = false;

  if (nameValid && emailValid && textValid) {
    formIsValid = true;
  }

  const sendContact = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://smtp.m1.websupport.sk", {
        body: JSON.stringify({
          name: name,
          email: email,
          text: text,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response);
      if (response.status !== 200) {
        throw new Error("Invalid response");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // setLoading(false);

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (!formIsValid) {
      return;
    }
    await sendContact();
    console.log(name, email, text);
    nameReset("");
    emailReset("");
    textReset("");
  };

  const invalidName = nameError ? "form-invalid" : ContactSuccess;
  const invalidEmail = emailError ? "form-invalid" : ContactSuccess;

  //loading for fun
  setTimeout(() => {
    setLoading(false);
  }, 1000);

  return (
    <>
      {loading ? (
        <Styles.LoadingParent>
          <Styles.LoadingSVG></Styles.LoadingSVG>
        </Styles.LoadingParent>
      ) : (
        <>
          <ContactTitle isLoading={loading}>
            Whether you have a project in mind, a job offer, or just a question
            about my portfolio, I'd love to hear from you.
          </ContactTitle>
          <ContactForm onSubmit={onSubmitHandler} isLoading={loading}>
            <div className={invalidName}>
              <ContactLabel htmlFor="name">Your name:</ContactLabel>
              <ContactInput
                type="text"
                name="name"
                onChange={nameChangeHandler}
                onBlur={nameBlur}
                value={name}
                autoFocus
              />
              {nameError && formIsValid && (
                <p className="errorText">Don't forget your name!</p>
              )}
            </div>
            <div className={invalidEmail}>
              <ContactLabel>
                Your Email:
                <ContactInput
                  type="email"
                  value={email}
                  onChange={emailChangeHandler}
                  onBlur={emailBlur}
                  placeholder="example@example.com"
                />
              </ContactLabel>
            </div>
            <div>
              <ContactLabel>
                What can I help you with?
                <ContactTextArea
                  value={text}
                  onChange={textChangeHandler}
                  onBlur={textBlur}
                  minLength={10}
                  type="text"
                  placeholder="Your request..."
                />
              </ContactLabel>
            </div>
            <div className="around-button">
              <ContactButton disabled={!formIsValid}>
                Contact Patrick
              </ContactButton>
            </div>
          </ContactForm>
          <Styles.SocialIcons>
            <a
              href="https://github.com/Patrixxk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillGithub />
            </a>
            <a
              href="https://www.linkedin.com/in/patrick-karkusz-666a87234/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillLinkedin />
            </a>
            <a
              href="https://discord.gg/x5C3W64j"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsDiscord />
            </a>
          </Styles.SocialIcons>
        </>
      )}
    </>
  );
}

export default Contact;

//Styles
const ContactForm = styled.form`
  opacity: ${(props) => (props.isLoading ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
  visibility: ${(props) => (props.isLoading ? "hidden" : "visible")};

  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--primary-color);
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 600px;
  margin: 0 auto;

  // @media (min-width: 768px) {
  //   gap: 2rem;
  //   padding: 3rem;
  //   border-radius: 1rem;
  //   width: 80%;
  // }
  @media only screen and (max-width: 500px) {
    width: 360px;
    margin: 2rem auto;
    padding: 1.5rem;
  }
  @media only screen and (min-width: 501px) and (max-width: 768px) {
    gap: 2rem;
    padding: 3rem;
    border-radius: 1rem;
    width: 80%;
  }
`;

const ContactInput = styled.input`
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1rem;
  width: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: #000000;
  transition: box-shadow 0.3s ease;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  }
`;

const ContactTextArea = styled.textarea`
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1rem;
  resize: none;
  height: 120px;
  width: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: #000000;
  transition: box-shadow 0.3s ease;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  }
`;

const ContactLabel = styled.label`
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--fourth-color);
`;

const ContactButton = styled.button`
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background-color: var(--fourth-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #a94949;
  }
  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`;

const ContactError = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;

const ContactSuccess = styled.p`
  color: green;
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;

const ContactTitle = styled.h3`
  opacity: ${(props) => (props.isLoading ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
  visibility: ${(props) => (props.isLoading ? "hidden" : "visible")};

  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
  text-shadow: 2px 2px 2px var(--fourth-color);
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #f5f5f5;
  @media only screen and (max-width: 500px) {
    font-size: 16px;
    margin: 2rem auto;
  }
  @media only screen and (min-width: 501px) and (max-width: 768px) {
    font-size: 1rem;
    margin: 2rem auto;
  }
  @media only screen and (min-width: 769px) and (max-width: 992px) {
    font-size: 1.3rem;
    margin: 2rem auto;
  }
`;
const Styles = {
  SocialIcons: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 3rem;
    margin: 2rem auto;

    svg {
      fill: var(--text-color);
      cursor: pointer;
      transition: 0.5s ease-out;
      @media (hover: hover) {
        &:hover {
          fill: var(--fourth-color);
          transform: scale(1.4);
          transition: font-size fill 0.5s ease-in-out;
        }
      }
    }
    @media only screen and (max-width: 500px) {
      font-size: 2rem;
    }
    @media only screen and (min-width: 501px) and (max-width: 768px) {
      font-size: 2.5rem;
    }
  `,
  LoadingParent: styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
  `,
  LoadingSVG: styled.span`
    display: block;
    position: absolute;
    height: 12px;
    width: 60%;
    border: 1px solid #fff;
    border-radius: 10px;
    overflow: hidden;
    &::after {
      content: "";
      width: 40%;
      height: 100%;
      background: var(--fourth-color);
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      animation: animloader 2s linear infinite;
    }
    @keyframes animloader {
      0% {
        left: 0;
        transform: translateX(-100%);
      }
      100% {
        left: 100%;
        transform: translateX(0%);
      }
    }
  `,
};
