import classes from "./ErrorPage.module.css";
const ErrorPage = ({ title, children }) => {
  return (
    <div className={classes.errorParent}>
      <h1>{title}</h1>
      {children}
    </div>
  );
};

export default ErrorPage;
