import { useRouteError } from "react-router-dom";
import ErrorPage from "../components/ErrorPage";
import NavigationBar from "../components/NavigationBar";

function Error() {
  const error = useRouteError();
  let title = "An error has occured";
  let message = "Something went wrong";
  if (error.status === 500) {
    message = JSON.parse(error.data).message;
  }
  if (error.status === 404) {
    title = "Not Found";
    message = "Could not find resource or page";
  }
  return (
    <>
      <NavigationBar />
      <ErrorPage title={title}>
        <p>{message}</p>
      </ErrorPage>
    </>
  );
}

export default Error;
