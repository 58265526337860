import React, { useState, useEffect } from "react";
import "./work.css";
import { useLoaderData, useNavigation } from "react-router-dom";
import styled from "styled-components";
import { WORK } from "./constans";
import { WorkBar } from "./workBar";

const Work = () => {
  const data = useLoaderData();
  const [currentPage, setCurrentPage] = useState(0);
  const [works, setWorks] = useState([]);
  const [loading, setLoading] = useState(true);
  const PRODUCTS_PER_PAGE = 4;

  useEffect(() => {
    const loadedWork = Object.keys(data).map((key) => ({
      id: key,
      title: data[key].title,
      description: data[key].description,
      language: data[key].language,
      image: data[key].image,
      link: data[key].link,
    }));
    setWorks(loadedWork);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const handleNext = () => {
    setCurrentPage((oldPage) => oldPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((oldPage) => Math.max(oldPage - 1, 0));
  };
  const offset = currentPage * PRODUCTS_PER_PAGE;

  const currentPageData = works
    .slice(offset, offset + PRODUCTS_PER_PAGE)
    .map((product, i) => {
      return <WorkBar works={product} />;
    });

  return (
    <React.Fragment>
      {loading ? (
        <Styles.LoadingParent>
          <Styles.LoadingSVG></Styles.LoadingSVG>
        </Styles.LoadingParent>
      ) : (
        <>
          <Styles.WorkParent isLoading={loading}>
            {currentPageData}
            <Styles.ButtonParent>
              <Styles.RoundButton
                onClick={handlePrevious}
                disabled={currentPage === 0}
              >
                {"<"}
              </Styles.RoundButton>
              <Styles.RoundButton
                onClick={handleNext}
                disabled={offset + PRODUCTS_PER_PAGE >= works.length}
              >
                {">"}
              </Styles.RoundButton>
            </Styles.ButtonParent>
          </Styles.WorkParent>
        </>
      )}
    </React.Fragment>
  );
};

export default Work;
export const loader = async () => {
  const response = await fetch(WORK);
  if (!response.ok) {
    throw new Response(
      JSON.stringify({
        message:
          "The fetch of works has failed, please reload the site or try later",
      }),
      {
        status: 500,
      }
    );
  } else {
    return response;
  }
};
const Styles = {
  WorkParent: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 18px;
    grid-template-areas:
      "area1 area2"
      "area3 area4";

    background: var(--primary-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 10px 15px rgba(25, 25, 25, 0.5);

    opacity: ${(props) => (props.isLoading ? 0 : 1)};
    transition: opacity 0.5s ease-in-out;
    visibility: ${(props) => (props.isLoading ? "hidden" : "visible")};

    @media only screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      margin: 1rem 1rem;
    }
    @media only screen and (min-width: 501px) and (max-width: 768px) {
      max-width: 95%;
      margin: 1rem auto;
      display: flex;
      flex-direction: column;
    }
    @media only screen and (min-width: 769px) and (max-width: 992px) {
      max-width: 95%;
      margin: 1rem auto;
    }
    @media only screen and (min-width: 993px) and (max-width: 1200px) {
      max-width: 100%;
      margin: 1rem auto;
    }
  `,
  ButtonParent: styled.div`
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
  `,
  RoundButton: styled.button`
    border-radius: 50%;
    width: 50px;
    height: 50px;

    border: none;
    cursor: pointer;
    font-size: 20px;
    background-color: #cd5c5c;
    color: #ffffff;
    box-shadow: 0 3px 5px 2px rgba(14, 62, 14, 0.3); // soft shadow
    color: var(--primary-color);
    transition: 0.3s;

    &:hover {
      background: var(--fourth-color);
      box-shadow: 3px 2px 15px #a94949;
      transform: scale(1.05);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  `,
  LoadingParent: styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
  `,
  LoadingSVG: styled.span`
    display: block;
    position: absolute;
    height: 12px;
    width: 60%;
    border: 1px solid #fff;
    border-radius: 10px;
    overflow: hidden;
    &::after {
      content: "";
      width: 40%;
      height: 100%;
      background: var(--fourth-color);
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      animation: animloader 2s linear infinite;
    }
    @keyframes animloader {
      0% {
        left: 0;
        transform: translateX(-100%);
      }
      100% {
        left: 100%;
        transform: translateX(0%);
      }
    }
  `,
};
