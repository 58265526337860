import {
  IoLogoJavascript,
  IoLogoHtml5,
  IoLogoCss3,
  IoLogoWordpress,
} from "react-icons/io";
import {
  BiLogoTypescript,
  BiLogoReact,
  BiLogoPhp,
  BiLogoShopify,
} from "react-icons/bi";
import { SiPrestashop } from "react-icons/si";

export const skills = [
  { type: "Javascript", level: 75 },
  { type: "React", level: 68 },
  { type: "HTML", level: 80 },
  { type: "CSS", level: 75 },
  { type: "Typescript", level: 40 },
];
export const profileDescription =
  "I'm a junior Frontend Developer with a year of freelance experience.";

export const colors = {
  bar: "#802000",
  title: {
    text: "#D3D3D3",
    background: "#2C2C2C",
  },
};
export const WORK =
  "https://patricks-portfolio-8cc03-default-rtdb.europe-west1.firebasedatabase.app/works.json";
export const icons = {
  javascript: <IoLogoJavascript />,
  html: <IoLogoHtml5 />,
  css: <IoLogoCss3 />,
  react: <BiLogoReact />,
  php: <BiLogoPhp />,
  woocommerce: <IoLogoWordpress />,
  typescript: <BiLogoTypescript />,
  prestashop: <SiPrestashop />,
  shopify: <BiLogoShopify />,
};

export const SKILLS = [
  {
    title: "javascript",
    logo: icons.javascript,
  },
  {
    title: "react",
    logo: icons.react,
  },
  { title: "typescript", logo: icons.typescript },
  { title: "wordpress", logo: icons.woocommerce },
  { title: "css", logo: icons.css },
];
