import { useState } from "react";

const Inputs = (validation) => {
  const [value, setValue] = useState("");
  const [touched, setTouched] = useState(false);

  const valueIsValid = validation(value);
  const hasError = !valueIsValid && touched;

  const valueChangeHandler = (e) => {
    setValue(e.target.value);
  };

  const blurHandler = () => {
    setTouched(true);
  };

  function reset() {
    setValue("");
  }

  return {
    value: value,
    valueIsValid: valueIsValid,
    hasError: hasError,
    valueChangeHandler,
    blurHandler,
    reset,
  };
};

export default Inputs;
