import classes from "./NavigationBar.module.css";
import { NavLink, Link } from "react-router-dom";

function NavigationBar() {
  function SlidetoElement(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <header className={classes.header}>
      <nav>
        <ul className={classes.list}>
          <li>
            <NavLink
              to="/"
              tag={Link}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              end
            >
              Home
            </NavLink>
          </li>
          <li>
            <Link
              to="#img-slider"
              onClick={() => SlidetoElement("img-slider")}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              end
            >
              My Work
            </Link>
          </li>
          <li>
            <NavLink
              to="/contact"
              tag={Link}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              end
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default NavigationBar;
